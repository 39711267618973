import { useQueryParams } from '../dependencies'

const LinkFail = ({ error }) => {
    const { 
        tagType,
    } = useQueryParams()
    const message = error === 'Tag not found' ? 'We were unable to link your' : 'Something went wrong while trying to link your'

    return (
        <div data-testid='link-fail' className='w-full mt-8 mb-8'>
            <h1 className='title' data-testid='title-linked-tag'>Download the app to continue</h1>
            <p className='subtitle !mb-0' data-testid='subtitle-linked-tag'>{message} {tagType}.</p>
        </div>
    )
}

export default LinkFail
