import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { constructRedirect, getRedirectInProgress, getRedirectUrl, setRedirectBase, setRedirectPath } from '@/entities/Progress'
import { Android, iPhone, useQueryParams } from '../dependencies'
import { getOutboundClaim } from '@/entities/Authentication'
import { getPublisherId } from '@/entities/Publisher'

const useRedirectLogic = () => {
    const dispatch = useDispatch()
    const { 
        tagId,
        inApp,
    } = useQueryParams()
    const outboundClaim = useSelector(getOutboundClaim)
    const redirectInProgress = useSelector(getRedirectInProgress)
    const visitedOniPhone = iPhone()
    const visitedOnAndroid = Android()
    const isAttemptingToLinkATag = !!tagId
    const publisherId = useSelector(getPublisherId)
    const shouldCloseAndGoBackToApp = inApp && !isAttemptingToLinkATag
    const shouldRedirectToWebApp = visitedOnAndroid && !isAttemptingToLinkATag
    const redirectUrl = useSelector(getRedirectUrl)

    useEffect(() => {
        if (inApp) {
            dispatch(setRedirectBase('hype://callback'))
        } else {
            dispatch(setRedirectBase(process.env.DASHBOARD_URL))
        }
        if (tagId) {
            dispatch(setRedirectPath('redirectTo=accessories&'))
        } else {
            dispatch(setRedirectPath('redirectTo=pages&'))
        }
        if (!outboundClaim && !redirectInProgress && publisherId) {
            dispatch(constructRedirect())
        }
    }, [dispatch, outboundClaim, redirectInProgress, inApp, tagId, publisherId])

    const onClickContinue = useCallback(() => {
        window.location.assign(redirectUrl)
    }, [redirectUrl])

    const onClickContinueAndroid = useCallback(() => {
        window.location.assign(redirectUrl)
    }, [redirectUrl])

    return {
        isAttemptingToLinkATag,
        visitedOnAndroid,
        visitedOniPhone,
        shouldRedirectToWebApp,
        shouldCloseAndGoBackToApp,
        redirectUrl,
        onClickContinue,
        onClickContinueAndroid,
    }
}

export default useRedirectLogic
