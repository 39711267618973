import Form from '@piconetworks/pkg-form'
import React, { useEffect } from 'react'
import { Transition } from '@headlessui/react'
import cx from 'classnames'
import { useForm, useFormState } from 'react-final-form'
import { showConsoleLogs } from '@/utils/console'
import Layout from '@/components/Layout'
import { useQueryParams } from '@/entities/QueryParams'

const Step = ({
    children,
    innerClasses,
    outerClasses,
    childrenClasses,
    title,
    subtitle = null,
    hasSubmitError = null,
    loading = false,
    hide = false,
    showHypeLogo,
}) => {
    const { mutators } = useForm()

    const {
        processed
    } = useQueryParams()
    const {
        dirtySinceLastSubmit,
        submitError,
        submitting,
        dirtyFieldsSinceLastSubmit,
        hasSubmitErrors,
    } = useFormState()
    
    showConsoleLogs() && console.log('inside of step', hasSubmitError, dirtySinceLastSubmit, submitError, submitting, dirtyFieldsSinceLastSubmit, hasSubmitErrors)

    useEffect(() => {
        if (hasSubmitError !== null && submitError !== hasSubmitError && !submitting && !dirtySinceLastSubmit) {
            showConsoleLogs() && console.log('setting error?', hasSubmitError)
            mutators.setError(hasSubmitError)
        } else if (dirtySinceLastSubmit || hasSubmitError === null) {
            showConsoleLogs() && console.log('unsetting error?')
            mutators.unsetError()
        }
    }, [hasSubmitError, dirtySinceLastSubmit, submitError, submitting, mutators])

    const stepTestId = `step-${loading ? 'loading' : 'ready'}`
    return (

        <Layout showHypeLogo={showHypeLogo}>
            <div className={cx('w-full md:w-1/2 flex flex-col grow relative max-w-xl overscroll-contain overflow-y-scroll scroll-smooth scrollbar-hide', outerClasses)}>
            {/* <div className={`flex flex-col w-full md:w-1/2 h-full align-center items-stretch relative max-w-xl ${outerClasses}`}> */}
                <section
                    className={cx({
                        [innerClasses]: !!innerClasses,
                        'loading animate-pulse': loading,
                        'invisible': hide
                    })}
                    data-testid={stepTestId}
                >
                    {hide && (
                        <svg className="animate-spin h-10 w-10 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    )}
                    
                    
                        {(title || subtitle) && (    
                            <div className={cx("max-w-[280px] mx-auto", {
                                'invisible': hide
                            })}>
                                {title && (
                                    <h1 className="title tracking-tighter" data-testid='title-step'>
                                        {title}
                                    </h1>
                                )}
                                {subtitle && (
                                    <p className="subtitle">
                                        {subtitle}
                                    </p>
                                )}
                            </div>
                        )}
                        {/* <div className='w-full h-full px-8 md:px-12'> */}
                        <div className={cx("flex flex-col", {
                                'invisible': hide,
                                [childrenClasses]: !!childrenClasses,
                            })}
                        >
                            {children}
                        </div>
                        {submitError &&
                            hasSubmitErrors &&
                            !dirtySinceLastSubmit &&
                            !submitting && (
                                <Form.Feedback variant="danger">{submitError}</Form.Feedback>
                            )
                        }
                </section>
            </div>
        </Layout>
    )
}

export default Step
