import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Image from 'next/image'
import {
    useQueryParams,
    Button,
    tagImageSource,
    useHydration,
    getScrapeyard,
    getPublisher,
    useTags,
} from './dependencies'
import Head from 'next/head'
import ActivationConfirmation from './components/ActivationConfirmation'
import ContinueOnWeb from './components/ContinueOnWeb'
import { useRouter } from 'next/router'
import useRedirectLogic from './hooks/useRedirectLogic'
import { getOutboundClaim } from '@/entities/Authentication'
import LinkFail from './components/LinkFail'

const DownloadWall = () => {
    const router = useRouter()
    const dispatch = useDispatch()
    const outboundClaim = useSelector(getOutboundClaim)
    const {
        tagType,
        tagColor,
        inApp,
        queryToKeep,
    } = useQueryParams()

    const {
        isAttemptingToLinkATag,
        visitedOnAndroid,
        visitedOniPhone,
        shouldRedirectToWebApp,
        shouldCloseAndGoBackToApp,
        redirectUrl,
        onClickContinue,
        onClickContinueAndroid,
    } = useRedirectLogic()
    console.log('download wall redirectUrl', redirectUrl)

    const {
        hasTagId,
        addTagToPublisherInProgress,
        addTagToPublisherError,
    } = useTags()

    const showAppStoreSmartBanner = !inApp && !visitedOnAndroid
    const _publisher = useSelector(getPublisher)
    const scrapeyard = useSelector(getScrapeyard)
    const downloadLink = `https://hypekit.app.link/Iv25Dn49GEb?${queryToKeep ? queryToKeep : ''}`
    const imageSource = (tagType && tagColor) ? tagImageSource(tagType, tagColor) : null
    const { requestHydration } = useHydration()

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (typeof plausible === 'function') {
                // eslint-disable-next-line no-undef
                plausible('Signup Complete')
            }

            if (typeof fbq === 'function') {
                // eslint-disable-next-line no-undef
                fbq('track', 'CompleteRegistration', { publisher_id: _publisher.id })
            }

            if (typeof gtag === 'function') {
                // eslint-disable-next-line no-undef
                gtag('event', 'registration_complete')
            }
        }

        requestHydration({
            publisherId: _publisher.publisherId,
            scrapeyard,
        })

        if ((shouldCloseAndGoBackToApp || shouldRedirectToWebApp) && redirectUrl.includes('claim') && outboundClaim) {
            router.push(redirectUrl)
        }
    }, [_publisher, scrapeyard, requestHydration, shouldCloseAndGoBackToApp, shouldRedirectToWebApp, redirectUrl, router, dispatch, outboundClaim])


    return (
        <>
            {showAppStoreSmartBanner && (
                <Head>
                    <meta name="apple-itunes-app" content="app-id=6451057733" />
                </Head>
            )}
            <div className='flex flex-col flex-nowrap items-center relative w-full h-full'>
                <>
                    {shouldCloseAndGoBackToApp === true || addTagToPublisherInProgress === true ? (
                        <h1 data-testid='loading-app' className='loading animate-pulse'>Loading</h1>
                    ) : (
                        <>
                            {/* <div className='mt-12 mb-8'>
                                {imageSource ? (
                                    <Image src={imageSource} alt={`${tagType} ${tagColor}`} width={250} height={250} priority />
                                ) : (
                                    <Image
                                        alt="Hype Brandmark"
                                        className="rounded-[32px]"
                                        src="/kittens/hype_brandmark_white.svg"
                                        width={150}
                                        height={150}
                                        priority
                                    />
                                )}
                            </div> */}

                            {isAttemptingToLinkATag && (
                                <>
                                    {addTagToPublisherError && !hasTagId ? (
                                        <LinkFail error={addTagToPublisherError} />
                                    ) : (
                                        <ActivationConfirmation includeSubtitle={!visitedOnAndroid && !inApp} />
                                    )}
                                </>
                            )}



                            {visitedOnAndroid && (
                                <Button
                                    type='button'
                                    onClick={onClickContinueAndroid}
                                    className='mt-8'
                                    variant='primary-black'
                                    fullWidth
                                    data-testid='android-button-continue'
                                >
                                    Continue
                                </Button>
                            )}
                            {inApp && isAttemptingToLinkATag ? (
                                <Button
                                    type='button'
                                    onClick={onClickContinue}
                                    className='mt-8'
                                    variant='primary'
                                    fullWidth
                                    data-testid='in-app-button-continue'
                                >
                                    Continue
                                </Button>
                            ) : (
                                <div className='flex flex-col flex-nowrap grow w-full items-center content-center justify-center text-center'>
                                    <div className='mb-8'>
                                        {!visitedOniPhone && !visitedOnAndroid && (
                                            <Image
                                                width={128}
                                                height={128}
                                                alt="Download QR Code"
                                                src="/kittens/qr.svg"
                                                priority
                                                className='mb-8 mx-auto'
                                            />
                                        )}
                                        {!visitedOnAndroid && (
                                            <a href={downloadLink}>
                                                <Image
                                                    width={181}
                                                    height={52}
                                                    alt="App Store badge"
                                                    src="/kittens/download.svg"
                                                    priority
                                                    className='mb-8 mx-auto my-8'
                                                />
                                            </a>
                                        )}

                                        {!isAttemptingToLinkATag && !visitedOnAndroid && !inApp && (
                                            <h1 className='title mt-3'>Download the app or continue to desktop</h1>
                                        )}


                                        {!visitedOniPhone && !visitedOnAndroid && (
                                            <ContinueOnWeb />
                                        )}
                                    </div>
                                    {visitedOnAndroid && (
                                        <ContinueOnWeb />
                                    )}

                                </div>
                            )}
                        </>
                    )}
                </>
            </div>
        </>
    )
}

export default DownloadWall
