import React, { useState } from 'react'

const SignupLoginToggle = ({ isLogin, setIsLogin }) => {
    return (
        <div className='flex justify-center'>
            {isLogin ? (
                <p className='text-sm font-normal text-black text-center text-opacity-50 leading-6'>
                    Don&apos;t have an account? <a className='cursor-pointer' data-testid='toggle-sign-up' onClick={() => setIsLogin(false)}>Sign up</a>
                </p>
            ) : (
                <p className='text-sm font-normal text-black text-center text-opacity-50 leading-6'>
                    Already have an account? <a className='cursor-pointer' data-testid='toggle-login' onClick={() => setIsLogin(true)}>Log in</a>
                </p>
            )}
        </div>
    )

}

export default SignupLoginToggle
