import React, { useCallback, useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { Transition } from '@headlessui/react'
import { showConsoleLogs } from '@/utils/console'
import {
    useAuthentication,
} from '@/entities/Authentication'
import {
    useProcessing,
} from '@/entities/Progress'
import {
    useQueryParams
} from '@/entities/QueryParams'

import AppleOption from './components/AppleOption'
import EmailLoginWithPassword from './components/EmailLoginWithPassword'
import EmailSignup from './components/EmailSignup'
import EmailSignupOption from './components/EmailSignupOption'
import GoogleOption from './components/GoogleOption'
import SignupLoginToggle from './components/SignupLoginToggle'

// In the case of vanilla signup and remixing, we want to create the publisher after the publisher user is created
// In the case of claiming a page, we don't want to create the publisher after the publisher user is created
// In the case of remixing a page, we aren't yet able to give the user the option to remix a page with an existing publisher, so we just want to log them in

// hiding login for remixing
const Authenticate = ({ isLogin = false, setIsLogin, showLoginToggle }) => {
    
    const {
        doesEmailExist,
        isEmailChecked,
        emailCheckMessage,
        onAuthenticate,
        runPasswordStrengthCheck,
        passwordStrength,
        passwordStrengthInProgress,
        emailEvaluated,
        isEmailExists,
        onEmailLogin
    } = useAuthentication()

    const {
        emailAuthInProgress,
        emailSignupInProgress,
        socialAuthInProgress,
        emailCheckInProgress,
        setupPublisherInProgress,
    } = useProcessing()

    const {
        tagId,
        email: emailFromQueryParams,
    } = useQueryParams()

    const { values: { email, password, firstName }, submitting, submitSucceeded } = useFormState()

    showConsoleLogs() && console.log('passwordStrength', passwordStrength, passwordStrengthInProgress)
    showConsoleLogs() && console.log('submitting', submitting, 'submitSucceeded', submitSucceeded)

    // i am not sure about this substep stuff, might be better to handle this using the router instead
    const [substep, setSubstep] = useState('a')

    // isSetupProcessing is concerned with setting up the account using google or with email/password
    // const [ isSetupProcessing, setIsSetupProcessing ] = useState(false)

    const handleGoogleLoginSuccess = useCallback(({ token = null, refresh_token = null, email = null, first_name = null, publisher_id = null, new_account = false, account_info = {}, ...rest }) => {
        showConsoleLogs() && console.log('google login success', rest, 'publisher_id', publisher_id, 'new_account', new_account)
        onAuthenticate({
            method: 'google',
            token,
            refresh_token,
            email,
            first_name,
            tag_id: tagId,
            publisher_id,
            new_account,
            is_a_god: account_info?.is_a_god,
        })
    }, [onAuthenticate, tagId])

    const handleGoogleLoginFailure = useCallback((error = {}) => {
        console.error(error)
        // setIsSetupProcessing(false)
        showConsoleLogs() && console.log(error)
    }, [])

    const handleAppleLoginSuccess = useCallback(({ token = null, refresh_token = null, email = null, first_name = null, publisher_id = null, new_account = false, account_info = {}, ...rest }) => {
        showConsoleLogs() && console.log('apple login success', rest, 'publisher_id', publisher_id, 'new_account', new_account)
        onAuthenticate({
            method: 'apple',
            token,
            refresh_token,
            email,
            first_name,
            tag_id: tagId,
            publisher_id,
            new_account,
            is_a_god: account_info?.is_a_god,
        })
        
    }, [onAuthenticate, tagId])

    const handleAppleLoginFailure = useCallback((error = {}) => {
        console.error(error)
        // setIsSetupProcessing(false)
        showConsoleLogs() && console.log(error)
    }, [])

    const handleCheckEmail = useCallback((value) => {
        showConsoleLogs() && console.log('handleCheckEmail', value)
        if (value && value !== emailEvaluated) {
            return doesEmailExist(value)
        }
    }, [doesEmailExist, emailEvaluated])

    useEffect(() => {
        if (emailFromQueryParams && email === emailFromQueryParams) {
            handleCheckEmail(emailFromQueryParams)
        }
    }, [emailFromQueryParams, email, handleCheckEmail])

    const handleSignupOrAuthViaEmail = useCallback((payload) => {
        showConsoleLogs() && console.log('handleSignupOrAuthViaEmail', payload)
        // setIsSetupProcessing(true)
        showConsoleLogs() && console.log('calling onAuthenticate email')
        onAuthenticate({
            method: 'email',
            password,
            firstName: firstName?.trim(),
            tagId,
            publisher_id: payload?.publisher_id || null,
            ...payload
        })

    }, [firstName, onAuthenticate, password, tagId])

    const handleLoginViaEmail = useCallback(() => {
        showConsoleLogs() && console.log('onEmailLogin', email)
        onEmailLogin({
            email,
            password,
            tagId,
        })

    }, [onEmailLogin, email, password, tagId])
    
    showConsoleLogs() && console.log('is email checked', isEmailChecked, emailCheckInProgress, emailCheckMessage)

    return (
        <>
            {substep === 'a' && (
                <div>
                    <div className='mb-8'>
                        {isLogin ? (
                            <>
                                <EmailLoginWithPassword 
                                    onContinue={(e) => handleLoginViaEmail(e)}
                                    processing={emailAuthInProgress || submitting || submitSucceeded || setupPublisherInProgress}
                                />
                            </>
                        ) : (
                            <EmailSignupOption handleClick={() => setSubstep('b')} processing={submitting} />
                        )}
                        <GoogleOption
                            isLogin={isLogin}
                            processing={socialAuthInProgress || submitting || submitSucceeded || setupPublisherInProgress}
                            onLoginSuccess={(e) => handleGoogleLoginSuccess(e)}
                            onLoginFailure={(e) => handleGoogleLoginFailure(e)}
                            tagId={tagId}
                        />
                        <AppleOption
                            isLogin={isLogin}
                            processing={socialAuthInProgress || submitting || submitSucceeded || setupPublisherInProgress}
                            onLoginSuccess={(e) => handleAppleLoginSuccess(e)}
                            onLoginFailure={(e) => handleAppleLoginFailure(e)}
                            tagId={tagId}
                        />
                    </div>

                    {showLoginToggle === true && <SignupLoginToggle isLogin={isLogin} setIsLogin={setIsLogin} />}
                </div>
            )}
            {substep === 'b' && (
                <Transition
                    show
                    appear
                    enter='transition-transform duration-100'
                    enterFrom='-translate-y-8'
                    enterTo='translate-y-0'
                >
                    <EmailSignup
                        emailCheckInProgress={emailCheckInProgress}
                        processing={emailAuthInProgress || emailSignupInProgress || setupPublisherInProgress || submitting}
                        onChange={(e) => handleCheckEmail(e)}
                        isEmailExists={isEmailExists}
                        onContinue={(e) => handleSignupOrAuthViaEmail({
                            email,
                            password,
                            firstName: firstName?.trim(),
                            tagId,
                        })}
                        emailCheckMessage={emailCheckMessage || null}
                        isEmailChecked={isEmailChecked && emailEvaluated === email}
                        handlePasswordStrength={(value) => runPasswordStrengthCheck(value)}
                        passwordStrength={passwordStrength}
                    />
                </Transition>
            )}
        </>
    )
}


export default Authenticate
