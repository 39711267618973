import React from 'react'
import { SocialLoginButton, PICO_API_URL, Button } from '../../dependencies'
import cx from 'classnames'
import { AppleIcon } from '../../icons'
import { showConsoleLogs } from '@/utils/console'

const renderSocialIcon = (provider: any) => {

    switch (provider) {
        case 'apple':
            return <AppleIcon />
            break
        default:
            return <div></div>
            break
    }
}

const AppleOption = ({ onLoginSuccess, onLoginFailure, processing, tagId, isLogin }) => {
    showConsoleLogs() && console.log('apple login processing', processing)
    return (
        <div className='flex justify-center'>
            <SocialLoginButton
                key={'apple'}
                scope='setup'
                params={{
                    is_combined: true,
                    tag_id: tagId
                }}
                type={'apple'}
                api_url={PICO_API_URL()}
                button={
                    <Button 
                        color='secondary'
                        fullWidth
                        startIcon={renderSocialIcon('apple')}
                        className={cx(
                            'btn-social',
                            {
                                processing: 'cursor-not-allowed'
                            }
                        )}
                        disabled={processing}
                    >
                        <span className={cx('w-full', 'plausible-event-name=Signup+Step+1a', 'plausible-event-method=Apple')}>{processing ? 'Processing...' : isLogin ? 'Log in with Apple' : 'Continue with Apple'}</span>
                    </Button>
                }
                onLogin={onLoginSuccess}
                onFailure={onLoginFailure}

            />
        </div>
    )

}

export default AppleOption