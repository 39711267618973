import React, { useEffect, useState } from 'react'
import { Button, composeValidators, fieldValidations } from '../../dependencies'
import { Field, useFormState } from 'react-final-form'
import cx from 'classnames'
import Form from '@piconetworks/pkg-form'
import { showConsoleLogs } from '@/utils/console'

const EmailLoginWithPassword = ({
    onContinue,
    processing,
}) => {

    const { values: { email }, valid, errors, submitError, dirtySinceLastSubmit } = useFormState()
    showConsoleLogs() && console.log('submitError', submitError, 'dirtySinceLastSubmit', dirtySinceLastSubmit, 'email', email)

    return (
        <>
            <Field
                name='email'
                component={Form.Input}
                autoComplete='email'
                label='Email'
                type='email'
                data-testid='input-email'
                required
                placeholder='email@example.com'
                validate={composeValidators(
                    fieldValidations.required,
                    fieldValidations.email,
                )}
            />
            <Field
                name='password'
                component={Form.Input}
                autoComplete='new-password'
                label='Password'
                type='password'
                data-testid='input-password'
                placeholder='Your password'
                required
                validate={composeValidators(fieldValidations.required)}
            />
            <Button
                className={cx('mt-6 md:mt-12 block', 'plausible-event-name=Signup+Step+1b', 'plausible-event-method=Login')}
                color='primary'
                fullWidth
                disabled={!valid || errors?.email || errors?.password || processing}
                loading={processing}
                data-testid='button-log-in'
                type='submit'
                onClick={(e) => onContinue(e)}
            >
                {processing ? 'Processing...' : 'Log in'}
            </Button>
        </>
    )
}
export default EmailLoginWithPassword
