import { useEffect, useState } from 'react'
import Image from 'next/image'
import qs from 'qs'
import cx from 'classnames'

import { useQueryParams } from '@/entities/QueryParams'
// import { useFeatureFlags } from '@/entities/FeatureFlags'
import GradientBg from '../GradientBg'
import { useProgress } from '@/entities/Progress'
import { Transition } from '@headlessui/react'

const Layout = ({ children, showHypeLogo = true }) => {
    const {
        utmSource,
        utmMedium,
        utmCampaign,
        utmTerm,
        utmContent,
        processed
    } = useQueryParams()

    const utmString = qs.stringify({
        utmSource,
        utmMedium,
        utmCampaign,
        utmTerm,
        utmContent,
    }, { skipNulls: true })

    const baseLink = 'https://hype.co'
    const [marketingLink, setMarketingLink] = useState(baseLink)
    // useFeatureFlags(true)
    useEffect(() => {
        if (utmString) {
            const newMarketingLink = `${baseLink}?${utmString}`

            if (marketingLink !== newMarketingLink) {
                setMarketingLink(newMarketingLink)
            }
        }
    }, [
        utmString,
        marketingLink,
    ])

    return (
        <main className="flex flex-col md:p-12 flex-nowrap items-center h-screen overflow-hidden">
            <div className={cx('flex flex-col shrink justify-center items-center  my-auto relative h-40')}>
                <GradientBg />
                {showHypeLogo === true && (
                    <>
                        <a href={marketingLink} target="_blank">
                            <Image
                                width={270}
                                height={48}
                                alt="Hype Logo"
                                className="absolute h-6 md:h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain"
                                src="/kittens/logo_lockup_white.svg"
                                priority
                            />
                        </a>
                    </>
                )}
            </div>
            
            {children}

        </main>
    )
}

export default Layout
