import React, { useState } from 'react'
import cx from 'classnames'

import { Button } from '../../dependencies'
import { EmailIcon } from '../../icons'

const EmailSignupOption = ({ processing = false, handleClick = (e: any) => {}, }) => {
    return (
        <div className='flex justify-center'>
            <Button 
                className='btn-secondary btn-social' 
                fullWidth
                startIcon={<EmailIcon />} 
                onClick={(e) => handleClick(e)} 
                data-testid='button-sign-up-with-email'
            >
                <span className={cx('w-full', 'plausible-event-name=Signup+Step+1a', 'plausible-event-method=Email')}>Continue with email</span>
            </Button>
        </div>
    )

}

export default EmailSignupOption
