export const iPhone = () => {
    // if window is defined
    if (typeof window !== 'undefined') {
        if (window.navigator?.userAgent) {
            // return true if userAgent contains iPhone
            return /iphone/.test(window.navigator.userAgent.toLowerCase())
        }
    }
}

export const Android = () => {
    // if window is defined
    if (typeof window !== 'undefined') {
        if (window.navigator?.userAgent) {
            // return true if userAgent contains android
            return /android/.test(window.navigator.userAgent.toLowerCase())
        }
    }
}