import { useCallback, useState, Fragment, useRef, } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import { Button, getRedirectUrl } from '../dependencies'
import { useRouter } from 'next/router'

const ContinueOnWeb = () => {
    const [showProceedToWeb, setShowProceedToWeb] = useState(false)
    const router = useRouter()
    const redirectUrl = useSelector(getRedirectUrl)

    const onClickContinueOnTheWeb = useCallback(() => {
        setShowProceedToWeb(true)
    }, [])

    const onClickConfirmOnTheWeb = useCallback(() => {
        router.push(redirectUrl)
    }, [redirectUrl, router])
  
    const cancelButtonRef = useRef(null)

    return (
        <>
            <div className='mb-6'>
                <Button type='button' onClick={onClickContinueOnTheWeb} size='md' variant='primary' data-testid='continue-on-web' fullWidth>
                    Continue on the web
                </Button>
            </div>
            <Transition.Root show={showProceedToWeb} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShowProceedToWeb}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden !rounded-[32px] bg-white !p-5 text-center shadow-xl transition-all sm:w-full sm:max-w-lg">
                                    <div>
                                    <div className="mt-3 text-center">
                                        <Dialog.Title as="h3" className="text-xl font-normal leading-6 text-gray-900 tracking-tight">
                                            Continue on web
                                        </Dialog.Title>
                                        <div className="mt-2">
                                        <p className="text-sm text-gray-700 tracking-wide ">
                                            The Hype web app may not have the latest features of the platform.
                                        </p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <Button 
                                            type='button' 
                                            onClick={() => setShowProceedToWeb(false)}
                                            size='md' 
                                            variant='primary-black' 
                                            fullWidth
                                        >
                                            Download the app
                                        </Button>
                                        <Button
                                            type='button'
                                            onClick={() => onClickConfirmOnTheWeb()}
                                            size='md'
                                            variant='secondary'
                                            fullWidth
                                            ref={cancelButtonRef}
                                        >
                                            Proceed to web anyway
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default ContinueOnWeb
