// @ts-nocheck
import { showConsoleLogs } from '@/utils/console'
import React from 'react'

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error = {}) {
        if (showConsoleLogs()) {
            console.log('inside of error boundary')
            console.error(error)
        }
        return { hasError: true }
    }

    constructor(props = {}){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error = {}) {
        console.error(error)
    }

    render() {
        const { hasError = false } = this.state
        const { children, RenderError } = this.props
        showConsoleLogs() && console.log('hasError', hasError)

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

ErrorBoundary.defaultProps = {
    RenderError: (error) => `${error.message}`,
}

export default ErrorBoundary
