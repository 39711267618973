import { Field } from 'react-final-form'
import Form from '@piconetworks/pkg-form'
import { composeValidators, fieldValidations } from '../../dependencies'

const SignupWithPasswordInput = ({ checkPasswordStrength, isPasswordFieldReady }) => {
    const rest = {
        ...isPasswordFieldReady && {
            autoFocus: true
        }
    }
    return (
        <>
            <Field
                name='password'
                component={Form.Input}
                autoComplete='new-password'
                label='Password'
                onChange={({ target }) => {
                    checkPasswordStrength(target.value)
                }}
                type='password'
                placeholder='Your password'
                required
                validate={composeValidators(fieldValidations.required)}
                {...rest}
            />
        </>
    )
}


export default SignupWithPasswordInput