export const passwordCollection = (result) => {
    let message

    if (!result || !result.feedback || !result.feedback.warning) {
        message = ''
    } else {
        message = result.feedback.warning
    }

    return [
        {
            key: 'empty',
            minPercentage: 0,
            maxPercentage: 0,
            message: 'Password Strength',
        },
        {
            key: 'dangerous',
            minPercentage: 1,
            maxPercentage: 20,
            message: 'Dangerous',
            messageDescription: message,
            color: '#FF4440',
        },
        {
            key: 'weak',
            minPercentage: 21,
            maxPercentage: 40,
            message: 'Weak',
            messageDescription: message,
            color: '#FCAF2F',
        },
        {
            key: 'average',
            minPercentage: 41,
            maxPercentage: 60,
            message: 'Average',
            color: '#7A34FF',
        },
        {
            key: 'strong',
            minPercentage: 61,
            maxPercentage: 80,
            message: 'Strong',
            color: '#28BF6F',
        },
        {
            key: 'strongest',
            minPercentage: 81,
            maxPercentage: 100,
            message: 'Strongest',
            color: '#209858',
        },
    ]
}

const PW_VALUE = {
    0: 0,
    1: 20,
    2: 40,
    3: 60,
    4: 80,
    5: 100,
}

export const passwordStrengthConversion = (score) => {
    if (score == null) {
        return 0
    }
    score += 1
    return PW_VALUE[score]
}
