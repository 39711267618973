import React, { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { Button, composeValidators, fieldValidations, useOneTimeCode } from '../../dependencies'
import { Field, useFormState } from 'react-final-form'
import cx from 'classnames'
import Form from '@piconetworks/pkg-form'
import ProgressBar from '@piconetworks/pkg-progress-bar'
import SignupWithPasswordInput from '../SignupWithPasswordInput'
import { showConsoleLogs } from '@/utils/console'
import { passwordCollection, passwordStrengthConversion } from '../../utils/password'
import Otp from '../OTP'

const EmailSignup = ({
    onChange,
    isEmailChecked,
    emailCheckMessage,
    onContinue,
    isEmailExists,
    handlePasswordStrength,
    passwordStrength,
    processing,
    emailCheckInProgress,
}) => {
    const {
        oneTimeCodeSent,
        oneTimeCodeConfirmed,
        requestOneTimeCode,
    } = useOneTimeCode()

    const { values: { email }, valid, errors, submitError, dirtySinceLastSubmit } = useFormState()
    showConsoleLogs() && console.log('submitError', submitError, 'dirtySinceLastSubmit', dirtySinceLastSubmit, 'email', email, 'isEmailChecked', isEmailChecked)
    // when we should show the login button and hide the password meter
    const [showLogin, setShowLogin] = useState(false)

    // isPasswordFieldReady is concerned with whether the email has been checked and the value matches what has been entered
    // determines if the password input should show
    const [isPasswordFieldReady, setIsPasswordFieldReady] = useState(false)

    useEffect(() => {
        if (isEmailChecked && !emailCheckInProgress) {
            setIsPasswordFieldReady(true)
            if (emailCheckMessage !== null) {
                return setShowLogin(true)
            } else {
                return setShowLogin(false)
            }
        }
    }, [isEmailChecked, emailCheckMessage, email, emailCheckInProgress])


    if (oneTimeCodeSent || oneTimeCodeConfirmed) {
        return (
            <Otp />
        )
    }

    return (
        <>
            <Field
                name='firstName'
                component={Form.Input}
                label='First name'
                data-testid='input-first-name'
                type='text'
                autoFocus
                required
                autoComplete='given-name'
                placeholder='Enter your first name'
                data-lpignore='true'
                validate={composeValidators(
                    fieldValidations.required,
                    fieldValidations.alphaStringFirstName
                )}
                className='input'
            />
            <Field
                name='email'
                component={Form.Input}
                autoComplete='email'
                label='Email'
                data-testid='input-email'
                type='email'
                required
                placeholder='email@example.com'
                format={(value) => value?.replace(/\s+/g,'')}
                parse={(value) => value?.replace(/\s+/g,'')}
                onChange={({ target }) => {
                    showConsoleLogs() && console.log('i changed', target.value)
                    setIsPasswordFieldReady(false)
                    return onChange(target.value)
                }}
                validate={composeValidators(
                    fieldValidations.required,
                    fieldValidations.email,
                )}
            />
            {isEmailChecked && (
                <Transition
                    show={isPasswordFieldReady}
                    enter='transition-opacity duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='transition-opacity duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <Transition
                        show={emailCheckMessage !== null}
                        enter='transition-opacity duration-200'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <p className='input-validation-text error -mt-2 mb-4'>{emailCheckMessage}</p>
                    </Transition>
                    {!isEmailExists && <SignupWithPasswordInput checkPasswordStrength={handlePasswordStrength} isPasswordFieldReady={isPasswordFieldReady} />}
                    <Transition
                        show={!showLogin}
                        enter='transition-transform duration-100'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <ProgressBar
                            collection={
                                passwordCollection(
                                    passwordStrength,
                                )
                            }
                            percentage={
                                passwordStrengthConversion(
                                    passwordStrength?.score,
                                )
                            }
                        />
                    </Transition>
                    <Button
                        className={cx('mt-6 md:mt-12 block', 'plausible-event-name=Signup+Step+1b', showLogin ? 'plausible-event-method=Login' : 'plausible-event-method=Email')}
                        color='primary'
                        fullWidth
                        data-testid='button-submit'
                        disabled={!valid || errors?.email || processing}
                        loading={processing}
                        type='submit'
                        onClick={(e) => showLogin ? requestOneTimeCode({ email}) : onContinue(e)}
                    >
                        {processing ? 'Processing...' : showLogin ? 'Log in' : 'Continue'}
                    </Button>
                </Transition>
            )}
        </>
    )
}
export default EmailSignup
