import React, { Children, useEffect } from 'react'
import { Form as RFF } from 'react-final-form'
import { useRouter } from 'next/router'

import { useProgress, usePublisher, usePublisherUser } from '../../dependencies'
import { showConsoleLogs } from '@/utils/console'
import { useAnalytics } from '@/entities/Analytics'
import isEmpty from 'lodash/isEmpty'
import { useSignupDetails } from '@/entities/Authentication'
import { useQueryParams } from '@/entities/QueryParams'
import mutators from './mutators'

const Wizard = ({ onSubmit, children }) => {
    
    const user = usePublisherUser()
    const { publisherId, selectedPublisher } = usePublisher()
    const { email } = useSignupDetails()

    const {
        email: emailFromQueryParams,
        name: nameFromQueryParams,
        queryToKeep,
        inApp,
    } = useQueryParams()

    showConsoleLogs() && console.log('Wizard selectedPublisher', selectedPublisher)
    const { pageLoadAction, pageSubmitAction } = useAnalytics({ user, publisherId, selectedPublisher })
    const { currentStep, completeStep, stepChangeInProgress, flow, setLastStep, setQueryParamsForRedirect, isLastStep, } = useProgress()
    const router = useRouter()

    /** @type {import('react').ReactElement} */ // @ts-ignore
    const activePage = Children.toArray(children)[currentStep - 1]
    const lastPage = Children.count(children)
    showConsoleLogs() && console.log('activePage', activePage, 'isLastStep', isLastStep)

    useEffect(() => {
        if (currentStep === 1) {
            setLastStep(lastPage)
            setQueryParamsForRedirect(queryToKeep)
        }
    }, [currentStep, lastPage, setLastStep, queryToKeep, setQueryParamsForRedirect, flow, inApp])

    useEffect(() => {
        pageLoadAction({
            page_number: currentStep,
        })
    }, [currentStep, pageLoadAction])

    useEffect(() => {
        if (stepChangeInProgress && activePage?.props?.hasSubmitError === null) {
            completeStep()
        }
    }, [activePage?.props, currentStep, stepChangeInProgress, flow, completeStep, router])

    const nextStep = (values, _formApi, callback) => {
        if (showConsoleLogs()) {
            console.groupCollapsed('Wizard nextStep')
            console.log('values', values)
            console.log('formApi', _formApi, callback)
            console.groupEnd()
        }
    }

    const validate = (values) => {
        return activePage?.props?.validate ? activePage?.props?.validate(values) : {}
    }

    const handleSubmit = async (values, _formApi, callback) => {
        // TODO use callback later for setting errors using [FORM_ERROR]
        if (isLastStep) {
            return await onSubmit(values, _formApi, callback)
        } else {
            if (showConsoleLogs()) {
                console.groupCollapsed('Wizard handleSubmit')
                console.log('values', values)
                console.log('user', user)
                console.log('publisherId', publisherId)
                console.log('currentStep', currentStep)
                console.groupEnd()
            }
            pageSubmitAction({
                eventData: {
                    ...values,
                    experiments: {},
                    auth_method: 'email',
                    page_number: currentStep,
                    email: email,
                    username: selectedPublisher?.username || values?.firstName || values?.first_name || email,
                    ...(!isEmpty(user) && { user_id: user?.id }),
                    ...(!publisherId && { publisher_id: publisherId }),
                },
                eventType: 'page_submit',
            })
            return nextStep(values, _formApi, callback)
        }
    }

    return (
        <RFF
            validate={validate}
            initialValues={{
                ...emailFromQueryParams && { email: emailFromQueryParams },
                ...nameFromQueryParams && { firstName: nameFromQueryParams },
            }}
            onSubmit={async (values, _formApi, callback) => await handleSubmit(values, _formApi, callback)}
            mutators={mutators}
        >
            {({ handleSubmit, submitting, values, submitError, form }) => {
                showConsoleLogs() && console.log('has a submit error in the form', submitError, form)
                return (
                    <form onSubmit={(values, formApi, callback) => handleSubmit(values, formApi, callback)}>
                        {activePage}
                    </form>
                )
            }}
        </RFF>
    )
}

export default Wizard
