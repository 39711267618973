import { PICO_API_URL } from '@piconetworks/pkg-endpoints'
import SocialLoginButton from '@piconetworks/pkg-social-login-button'
import { composeValidators, fieldValidations } from '@/utils/form/validators'
import isEmpty from 'lodash/isEmpty'
// import { useUser } from '@/entities/User'

import {
    getIsNewUser,
    authWithEmailPassword,
    authWithSocial,
    useSignupDetails,
    useAuthentication,
    useOneTimeCode
    // useCheckHasPassword,
} from '@/entities/Authentication'

import {
    useProcessing,
    useProgress
} from '@/entities/Progress'

import {
    usePublisher,
    usePublisherUser,
    setupPublisher,
    getSetupPublisherInProgress
} from '@/entities/Publisher'

import {
    useQueryParams
} from '@/entities/QueryParams'

import Button from '@/components/Button'

export {
    PICO_API_URL,
    getIsNewUser,

    // useCheckHasPassword,
    usePublisher,
    usePublisherUser,
    useAuthentication,
    useProcessing,
    useQueryParams,
    // useUser,
    useSignupDetails,
    authWithEmailPassword,
    authWithSocial,
    SocialLoginButton,
    useOneTimeCode,
    composeValidators,
    fieldValidations,
    isEmpty,
    setupPublisher,
    getSetupPublisherInProgress,
    Button,
    useProgress
}
