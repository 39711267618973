import { useQueryParams } from '../dependencies'

const ActivationConfirmation = ({ includeSubtitle = true }) => {
  const {
    tagPackSize,
    tagType,
} = useQueryParams()
    const isSticker = tagType === ('sticker' || 'mini_sticker')
    const isStickerPack = parseInt(tagPackSize) > 1
    const title = `Your ${isStickerPack ? 'stickers have' : isSticker ? 'sticker has' : 'card has'} been successfully activated.`
    return (
        <div data-testid='activation-confirmation' className='w-full mt-8 mb-8'>
            <h1 className='title' data-testid='title-linked-tag'>{title}</h1>
            {includeSubtitle && (
                <p className='subtitle !mb-0' data-testid='subtitle-linked-tag'>Download the app to continue.</p>
            )}
        </div>
    )
}

export default ActivationConfirmation
