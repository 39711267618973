import { showConsoleLogs } from '@/utils/console'

const mutators = {
    setError: (args, state) => {
        // console.log('setError', args, state)
        if (args && args[0]) {
            state.formState.lastSubmittedValues = state.formState.values
            state.formState.submitFailed = true
            state.formState.submitSucceeded = false
            state.formState.submitError = args[0]
        }
    },
    setFieldError: (args, state) => {
        showConsoleLogs() && console.log('setFieldError', args, state)
        if (args && args[0] && args[1]) {
            // state.formState.lastSubmittedValues = state.formState.values
            state.formState.asyncErrors[args[0]] = args[1]
            showConsoleLogs() && console.log('setFieldError after', state.formState.asyncErrors)
        }
    },
    changeUsernameValue: (args, state, utils) => {
        showConsoleLogs() && console.log('changeUsernameValue', args, state)
        if (args && args[0]) {
            // utils.changeValue(state, 'username', () => args[0])
            state.formState.values.username = args[0]
        }
    },
    unsetFieldError: (args, state) => {
        // console.log('unsetFieldError', args, state)
        // state.formState.lastSubmittedValues = state.formState.values
        state.formState.asyncErrors = {}
        showConsoleLogs() && console.log('unsetFieldError after', state.formState.asyncErrors)
    },
    unsetError: (args, state) => {
        // console.log('unsetError', args, state)
        showConsoleLogs() && console.log('unsetError', args, state)
        state.formState.submitFailed = false
        state.formState.submitError = undefined
    },
    setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value)
    },
}


export default mutators